body {
  .ant-popover-inner {
    background-color: #f0f2f5;
  }

  .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
  .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
    border-color: #f0f2f5;
  }
}

#root {
  height: 100%;
}

.app-layout {
  height: auto;
  min-height: 100%;
}

.ant-table-body > table {
  border: 1px solid #eeeeee;
}

.ant-table-placeholder {
  border-right: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
}

